<template>
  <div class="receive-detail">
    <breadcrumb style="margin-left: 15px; margin-bottom: -5px"></breadcrumb>
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="operateReceivableOrder"
            :text="$t('审核')"
            :params="{
              action: 'check',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="isDetail && ['WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            api="operateReceivableOrder"
            :text="$t('作废')"
            type="danger"
            :params="{
              action: 'cancel',
              idList: [id],
            }"
            @success="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <div id="BaseInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('基本信息') }}</span>
                  <span class="text-link fz14 ml10" v-if="isDetail" @click="copyOrder">
                    {{ $t('复制单据') }}
                  </span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`应付单号`)">
                    <a-input disabled v-model="formData.orderSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :list="receivable_order_type"
                      :code.sync="formData.orderType"
                      :placeholder="$t('单据类型')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizDate"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算客户`)" required>
                    <CommonQuerySelect
                      api="getCommonList"
                      :disabled="isDisabled"
                      :code.sync="formData.customerCode"
                      :name.sync="formData.customerName"
                      :params="{
                        businessUnitCode: formData.businessUnitCode,
                        tableName: 'customer',
                      }"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款组织`)" required>
                    <CommonQuerySelect
                      :init="true"
                      api="getBusinessUnitList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      :placeholder="$t(`收款组织`)"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算方式`)" required>
                    <SelectSettlementMethod
                      :code.sync="formData.settlementMethod"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`原始销售单号`)">
                    <a-input :disabled="isDisabled" v-model="formData.salesOrderSn" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input v-model="formData.remark" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <template v-if="isDetail">
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`状态`)">
                      <a-input disabled v-model="receivableOrderStatusMapping[formData.status]" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`创建时间`)">
                      <a-input disabled v-model="formData.createdAt" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`源单类型`)">
                      <a-input
                        disabled
                        v-model="sourceOrderTypeMapping[formData.sourceOrderType]"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`源单编码`)">
                      <a-input disabled v-model="formData.sourceOrderCode" />
                    </a-form-model-item>
                  </a-col>
                </template>
              </a-row>
            </div>
            <div id="AmountInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('金额信息') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)">
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算货币`)">
                    <SelectCurrency
                      :disabled="isDisabled"
                      :code.sync="formData.settlementCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item required :label="$t(`金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.settlementCurrency"
                      :value="formData.amountWithoutTax"
                      :placeholder="$t(`金额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- <a-col :span="6">
                  <a-form-model-item :label="$t(`税率`)" required>
                    <a-input-number
                      style="width: 100%"
                      :disabled="isDisabled"
                      v-model="formData.taxRate"
                      :placeholder="$t(`税率`)"
                      @blur="inputVATRateFn"
                    />
                  </a-form-model-item>
                </a-col> -->
                <a-col :span="6">
                  <a-form-model-item :label="$t(`税额`)">
                    <PriceInput
                      :disabled="true"
                      :currency="formData.settlementCurrency"
                      :value="formData.taxAmount"
                      :placeholder="$t(`税额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`价税合计`)">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :disabled="true"
                      :value.sync="formData.receivableAmount"
                      :placeholder="$t(`价税合计`)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </a-form-model>

          <div id="ProductInfo" class="modalBox">
            <div>
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  {{ $t('明细') }}
                </div>
              </div>
              <div class="pricing-method flex-ct">
                <a-form-model-item :label="$t(`计价方式`)" required>
                  <a-select
                    :disabled="isDisabled"
                    :placeholder="$t(`请选择计价方式`)"
                    v-model="calculateTypeNew"
                    @change="scrollToBottom"
                    style="width: 300px"
                  >
                    <a-select-option value="BY_TOTAL">
                      {{ $t('通过小计计算单价') }}
                    </a-select-option>
                    <a-select-option value="BY_UNIT">
                      {{ $t('通过单价计算小计') }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <div class="mt20 ml20" v-if="calculateTypeNew === 'BY_UNIT'">
                  <a-popover placement="topLeft">
                    <template slot="content">
                      <div>
                        {{ $t('若开启：含税单价可录入，若关闭：单价可录入') }}
                      </div>
                    </template>
                    <span>{{ $t('录入含税单价') }}</span>
                    <a-icon type="question-circle" class="ml6 mr20" />
                  </a-popover>
                  <a-switch v-model="byUnitTaxPrice" :disabled="isDisabled" />
                </div>
              </div>
              <div v-if="!isDisabled" style="display: flex; justify-content: space-between">
                <div style="position: relative; flex: 1">
                  <SearchSku style="width: 300px" @selected="handleChangeSku" />
                  <a-button type="primary" style="margin-left: 15px" @click="addGoodsToTable">
                    <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
                    {{ $t('form.Add') }}
                  </a-button>
                </div>
                <div class="flex-ct txt fz16" style="font-weight: bold">
                  <div class="mr30 flex-ct">
                    {{ $t('数量合计') }}: {{ purchaseAllQuantity | toThousands }}
                  </div>
                  <div class="mr30 flex-ct">
                    {{ $t('总金额合计') }}:
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="purchaseAllAmount"
                      :showTxt="true"
                    />
                  </div>
                  <div class="flex-ct">
                    {{ $t('总价税合计') }}:
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="totalReceivableAmount"
                      :showTxt="true"
                    />
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="margin-top: 10px">
                <a-table
                  :columns="columns"
                  :dataSource="tableData"
                  bordered
                  size="middle"
                  :loading="tableLoading"
                  :pagination="false"
                  :scroll="{ x: 1500 }"
                  :rowKey="(record, index) => index"
                >
                  <span slot="CommoditySpecification">
                    {{ $t(`form.CommoditySpecification`) }}
                  </span>
                  <span slot="Remark">
                    {{ $t(`form.Remark`) }}
                  </span>
                  <span slot="discountAmountTitle">
                    <a-tooltip>
                      <template slot="title">
                        {{ $t('当单价乘以数量与小计不等时展示优惠金额') }}
                      </template>
                      {{ $t(`优惠金额`) }}
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <span slot="Operation">
                    {{ $t(`form.Operation`) }}
                  </span>
                  <span slot="no" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </span>
                  <span slot="productName" slot-scope="text, record">
                    <div v-if="record.itemName">{{ $t('名称') }}:{{ record.itemName }}</div>
                    <div v-if="record.gtin">{{ $t('代码') }}:{{ record.gtin }}</div>
                  </span>
                  <template slot="itemStyle" slot-scope="text">
                    {{ text }}
                  </template>
                  <span slot="unit" slot-scope="text, record">
                    <SelectUnit
                      :disabled="isDisabled"
                      :list="record.unitList"
                      :code.sync="record.rawUnit"
                    />
                  </span>
                  <span slot="taxAmount" slot-scope="text, record">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value.sync="record.taxAmount"
                      :showTxt="true"
                    />
                  </span>
                  <span slot="remark" slot-scope="text, record">
                    <a-input v-model="record.remark" :disabled="isDisabled" />
                  </span>
                  <span slot="action" slot-scope="text, record, index">
                    <a @click="onDelect(index)">
                      <a-icon type="delete" style="color: red" />
                    </a>
                  </span>
                  <template v-for="col in customRenderList" v-slot:[col]="text, record, index">
                    <div :key="col">
                      <!-- includes 用来判断当前的输入类型 -->
                      <div v-if="!isDisabled">
                        <a-input-number
                          v-if="col === 'taxRate' || col === 'quantity'"
                          :placeholder="$t(`form.PleaseEnter`)"
                          :value="text"
                          @blur="(e) => handleChange(e.target.value, col, index)"
                        />
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          v-else-if="col === 'unitPrice' || col === 'unitPriceWithTax'"
                          :placeholder="$t(`form.PleaseEnter`)"
                          :disabled="getUnitPriceDisabled(col)"
                          :value="text"
                          @blur="(val) => handleChange(val, col, index)"
                        />
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          v-else-if="col === 'amountWithoutTax'"
                          :placeholder="$t(`form.PleaseEnter`)"
                          :disabled="calculateTypeNew === 'BY_UNIT'"
                          :value="text"
                          @blur="(val) => handleChange(val, col, index)"
                        />
                        <span v-else>
                          <PriceInput
                            :currency="formData.settlementCurrency"
                            :value="text"
                            :showTxt="true"
                          />
                        </span>
                      </div>
                      <span v-else-if="col === 'taxRate' || col === 'quantity'">
                        {{ Number(text) }}
                      </span>
                      <span v-else>
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          :value="text"
                          :showTxt="true"
                        />
                      </span>
                    </div>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import SelectSettlementMethod from '@component/selectSettlementMethod'
import SelectUnit from '@component/selectUnit'
import SearchSku from '@component/searchSku'
import deepClone from '@/common/deepClone'
import { add, divide, multiply } from '@/common/number'
import { toFixedUp, convertKeysToCamelCase, getUnitRate } from '@/common'
import debounce from '@/common/debounce'
export default {
  name: 'ReceivableDetail',
  components: {
    DatePicker,
    BottomBtns,
    SelectCurrency,
    SelectSettlementMethod,
    SelectUnit,
    SearchSku,
  },
  data() {
    return {
      id: undefined,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      // 表格数据
      tableData: [],
      // 每一列的插槽名 - 表格行内编辑用
      customRenderList: [
        'quantity',
        'taxRate',
        'unitPrice',
        'amountWithoutTax',
        'unitPriceWithTax',
      ],
      // 表格loading
      tableLoading: false,
      // 表格选中key
      selectedRowKeys: [],
      formData: {
        accountingDate: undefined,
        bizTime: undefined,
        warehouseCode: undefined,
        invoiceType: undefined,
        taxAmount: undefined,
        amountWithoutTax: undefined,
        receivableAmount: undefined,
        settlementMethod: undefined,
        functionalCurrency: 'CNY',
        settlementCurrency: 'CNY',
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      currentGood: {},
      purchaseAllAmount: 0,
      purchaseAllQuantity: 0,
      totalReceivableAmount: 0,
      supplierIndex: undefined,
      calculateTypeNew: 'BY_TOTAL',
      byUnitTaxPrice: true,
      cancelLoading: false,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['receivable_order_type']),
    ...mapGetters(['sourceOrderTypeMapping', 'receivableOrderStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    columns() {
      let list = [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          scopedSlots: {
            customRender: 'no',
          },
        },
        {
          title: this.$t('商品信息'),
          dataIndex: 'productName',
          width: 200,
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          slots: { title: 'CommoditySpecification' },
          align: 'center',
          dataIndex: 'itemStyle',
          width: 120,
          scopedSlots: {
            customRender: 'itemStyle',
          },
        },
        {
          align: 'center',
          dataIndex: 'unit',
          width: 100,
          title: this.$t('单位'),
          scopedSlots: {
            customRender: 'unit',
          },
        },
        {
          title: this.$t('数量'),
          align: 'center',
          dataIndex: 'quantity',
          width: 120,
          scopedSlots: {
            customRender: 'quantity',
          },
        },
        {
          title: this.$t('单价'),
          align: 'center',
          dataIndex: 'unitPrice',
          width: 120,
          scopedSlots: {
            customRender: 'unitPrice',
          },
        },
        {
          title: this.$t('税率') + '(%)',
          align: 'center',
          dataIndex: 'taxRate',
          width: 120,
          scopedSlots: {
            customRender: 'taxRate',
          },
        },
        {
          title: this.$t('税额'),
          align: 'center',
          dataIndex: 'taxAmount',
          width: 120,
          scopedSlots: {
            customRender: 'taxAmount',
          },
        },
        {
          title: this.$t('含税单价'),
          align: 'center',
          dataIndex: 'unitPriceWithTax',
          width: 120,
          scopedSlots: {
            customRender: 'unitPriceWithTax',
          },
        },
        {
          align: 'center',
          dataIndex: 'discountAmount',
          width: 120,
          slots: { title: 'discountAmountTitle' },
          customRender: (text) => this.showPrice(Number(text)),
        },
        {
          title: this.$t('金额'),
          align: 'center',
          dataIndex: 'amountWithoutTax',
          width: 120,
          scopedSlots: {
            customRender: 'amountWithoutTax',
          },
        },
        {
          title: this.$t('价税合计'),
          align: 'center',
          dataIndex: 'receivableAmount',
          width: 120,
          customRender: (text) => this.showPrice(Number(text)),
        },
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: {
            customRender: 'remark',
          },
        },
      ]
      if (this.isEdit || !this.isDetail) {
        list.push({
          title: this.$t('操作'),
          align: 'center',
          width: 120,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action',
          },
        })
      }
      return list
    },
  },
  methods: {
    init() {
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.bizTime = moment().format('YYYY-MM-DD')
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
      }
      this.isCopy = false
      this.isEdit = false
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency
    },
    showPrice(num) {
      if (num) {
        return this.$currencySymbol(num, this.formData.settlementCurrency)
      }
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    getDetailInfo() {
      let receivableOrderFormdata = localStorage.getItem('receivableOrderFormdata')
      if (this.id) {
        this.loading = true
        http({
          url: api.getReceivableDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.tableData = deepClone(result.items)
            this.formData = deepClone(result)
            this.loading = false
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
          },
          fail: (err) => {
            this.loading = false
            this.$message.error(err.msg)
          },
        })
      } else if (receivableOrderFormdata) {
        this.isCopy = true
        let data = JSON.parse(receivableOrderFormdata)
        this.formData = data
        this.formData.orderSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        delete this.formData.tableData
        localStorage.removeItem('receivableOrderFormdata')
        this.setTotalAmount()
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      this.formData.paymentPlanList = this.paymentPlanList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('receivableOrderFormdata', data)
      this.$router.push({ name: 'receivableDetail' })
    },
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'receivableList' })
    },
    onSubmit: debounce(async function (status) {
      if (this.tableData.length === 0) {
        this.$warning({
          title: this.$t(`layer.Warning`),
          content: this.$t(`请添加商品`),
        })
        return
      }
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      data.items = deepClone(this.tableData).map((item) => {
        let targetUnit = item.unitList.find((elem) => elem.unitNameFull === item.rawUnit) || {}
        let { rate } = getUnitRate({ list: item.unitList, targetUnit })
        item.baseQuantity = Math.round(multiply(item.quantity, rate))
        delete item.skuStyleList
        return item
      })
      data.merchantCode = localStorage.getItem('merchantCode')
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      await http({
        url: api.createReceivableOrder,
        data: {
          order: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$message.success({
              title: this.$t(`layer.Success`),
              content: this.$t(`layer.SavingSucceeded`),
            })
            if (this.isCopy) {
              this.backForm()
            } else {
              this.init()
            }
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    setTotalAmount() {
      this.calcAllAmount()
    },
    handleChangeSku(data) {
      this.currentGood = {
        amountWithoutTax: '',
        baseQuantity: '',
        baseUnit: '',
        discountAmount: '',
        discountRate: '',
        gtin: data.barcode,
        itemName: data.skuName,
        skuStyleList: data.skuStyleList,
        receivableAmount: '',
        quantity: '',
        rawUnit: '',
        taxAmount: '',
        taxRate: 0,
        unitPrice: '',
        unitPriceWithTax: '',
        unitList: data.unitList,
        remark: '',
      }
    },
    handleChange(value, colName, index) {
      const newData = [...this.tableData]
      const target = newData[index]
      if (target) {
        target[colName] = value
        this.calcGoodsAmount(colName)
        this.tableData = newData
      }
    },
    calcGoodsAmount(colName) {
      this.getTheAmountInPcFn(colName)
    },
    calcAllAmount() {
      let quantity = 0,
        amount = 0,
        taxAmount = 0,
        totalAmount = 0
      this.tableData.forEach((item) => {
        quantity = add(quantity, item.quantity)
        amount = add(amount, item.amountWithoutTax)
        taxAmount = add(taxAmount, item.taxAmount)
        totalAmount = add(totalAmount, item.receivableAmount)
      })
      this.purchaseAllQuantity = quantity
      this.totalReceivableAmount = Number(totalAmount.toFixed(4))
      this.formData.amountWithoutTax = this.purchaseAllAmount = Number(amount.toFixed(4))
      this.formData.taxAmount = Number(taxAmount.toFixed(4))

      let result = this.formData.amountWithoutTax * 1 + this.formData.taxAmount * 1
      this.formData.receivableAmount = isNaN(result) ? '' : Number(result.toFixed(4))
    },
    onDelect(index) {
      this.tableData.splice(index, 1)
      this.$nextTick(() => {
        this.calcGoodsAmount()
      })
    },
    addGoodsToTable() {
      if (this.currentGood.itemName) {
        this.currentGood.itemStyle = this.currentGood.skuStyleList
          .map((item) => item.styleValue)
          .join(';')
        let procurementUnitItem =
            this.currentGood.unitList?.find((item) => item.procurementUnit) || {},
          baseUnitItem = this.currentGood.unitList?.find((item) => item.baseUnit) || {}
        this.currentGood.rawUnit = procurementUnitItem.unitNameFull || baseUnitItem.unitNameFull
        this.currentGood.baseUnit = baseUnitItem.unitNameFull
        if (
          this.tableData.length &&
          this.tableData.findIndex((i) => i.gtin == this.currentGood.gtin) !== -1
        ) {
          return this.$message.error(this.$t('form.DoNotAddRepeatedly'))
        }
        this.tableData.push(this.currentGood)
        this.scrollToBottom()
      }
    },
    getTheAmountInPcFn() {
      if (!this.tableData.length) return
      let hasEmpty = false
      this.tableData.forEach((item) => {
        // console.log(item)
        item.taxAmount = 0
        if (item.quantity && (item.unitPrice || item.unitPriceWithTax || item.amountWithoutTax)) {
          //修改单价和数量 小计跟着改
          if (this.calculateTypeNew === 'BY_TOTAL') {
            item.unitPrice = toFixedUp(divide(item.amountWithoutTax, item.quantity), 4)
            item.unitPriceWithTax = multiply(
              divide(item.amountWithoutTax, item.quantity),
              1 + item.taxRate / 100
            ).toFixed(4)
            item.taxAmount = this.getAmountByRatio(item.taxRate, item.amountWithoutTax)
            item.receivableAmount = add(item.amountWithoutTax, item.taxAmount).toFixed(4)
            let calTotalAmount = multiply(item.quantity, item.unitPrice)
            item.discountAmount = (calTotalAmount - item.amountWithoutTax).toFixed(4)
          } else {
            if (this.byUnitTaxPrice) {
              item.unitPrice = toFixedUp(item.unitPriceWithTax * (1 / (1 + item.taxRate / 100)), 4)
            } else {
              item.unitPriceWithTax = (item.unitPrice * (1 + item.taxRate / 100)).toFixed(4)
            }
            item.amountWithoutTax = multiply(item.unitPrice, item.quantity).toFixed(4)
            item.taxAmount = this.getAmountByRatio(item.taxRate, item.amountWithoutTax)
            item.receivableAmount = multiply(item.unitPriceWithTax, item.quantity).toFixed(4)
            item.discountAmount = 0
            if (this.byUnitTaxPrice) {
              item.discountAmount = (
                add(item.amountWithoutTax, item.taxAmount).toFixed(4) - item.receivableAmount
              ).toFixed(4)
            }
          }
        } else {
          hasEmpty = true
        }
      })
      if (hasEmpty) return
      this.setTotalAmount()
    },
    getAmountByRatio(ratio, amount) {
      amount = amount || this.formData.receivableAmount
      return (multiply(amount, ratio) / 100).toFixed(4)
    },
    getRatioByAmount(rate, amount) {
      amount = amount || this.formData.receivableAmount
      return (divide(rate, amount) * 100).toFixed(4)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    getUnitPriceDisabled(col) {
      if (this.calculateTypeNew === 'BY_TOTAL') {
        return true
      } else {
        if (this.byUnitTaxPrice) {
          return col === 'unitPrice'
        } else {
          return col === 'unitPriceWithTax'
        }
      }
    },
  },
}
</script>
